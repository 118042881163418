import React from 'react';

import HubspotForm from 'react-hubspot-form';

const FormCard: React.FunctionComponent = () => (
  <HubspotForm
    portalId="20067109"
    formId="e5941088-8fb8-4b65-84d8-d2a500a25074"
  />
);

export default FormCard;
